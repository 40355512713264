import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import style from './contactForm.module.css'
import ContactSuccess from './contactSuccess'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = _ => {
  const { register, handleSubmit, errors } = useForm()

  const [isDisabled, setIsDisabled] = useState(false)

  const onSubmit = (data, e) => {
    e.preventDefault()
    const formName = e.target.getAttribute('name')
    const body = encode({
      'form-name': formName,
      ...data,
    })
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: body,
    })
      .then(() => console.log('Success!'))
      .catch(error => console.log(error))

    e.target.reset()
    setIsDisabled(!isDisabled)
  }

  return (
    <form
      aria-disabled={isDisabled}
      className={style.contactForm}
      data-disabled={isDisabled}
      data-netlify="true"
      data-netlify-honeypot="data-field"
      method="post"
      name="contact"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input name="form-name" type="hidden" value="contact" />
      <input
        hidden
        name="subject"
        ref={register()}
        defaultValue="Monodon - New Message"
      />
      <label
        htmlFor="fullName"
        className={`${style.contactFormLabel} super-paragraph`}
      >
        Name*
      </label>
      <input
        aria-describedby="error-fullName-required error-fullName-maxLength"
        aria-disabled={isDisabled}
        aria-invalid={errors.fullName ? 'true' : 'false'}
        aria-required="true"
        className={style.contactFormField}
        name="fullName"
        placeholder="Enter your full name"
        ref={register({ required: true, maxLength: 33 })}
        type="text"
      />
      {errors.fullName && errors.fullName.type === 'required' && (
        <div
          className={style.contactFormError}
          id="error-fullName-required"
          role="alert"
        >
          Please write your full name in the above field :)
        </div>
      )}
      {errors.fullName && errors.fullName.type === 'maxLength' && (
        <div
          className={style.contactFormError}
          id="error-fullName-maxLength"
          role="alert"
        >
          This name is waaaaayyyy too long:)
        </div>
      )}
      <label
        htmlFor="email"
        className={`${style.contactFormLabel} super-paragraph`}
      >
        Email*
      </label>
      <input
        aria-describedby="error-email-required error-email-validate"
        aria-disabled={isDisabled}
        aria-invalid={errors.email ? 'true' : 'false'}
        aria-required="true"
        className={style.contactFormField}
        name="email"
        placeholder="email@yourmail.com"
        ref={register({ required: true, validate: true })}
        type="email"
      />
      {errors.email && errors.email.type === 'required' && (
        <div
          className={style.contactFormError}
          id="error-email-required"
          role="alert"
        >
          Please write your email in the above field :)
        </div>
      )}
      {errors.email && errors.email.type === 'validate' && (
        <div
          className={style.contactFormError}
          id="error-email-validate"
          role="alert"
        >
          Please enter a valid email address :)
        </div>
      )}
      <label
        htmlFor="company"
        className={`${style.contactFormLabel} super-paragraph`}
      >
        Company / Organization*
      </label>
      <input
        aria-describedby="error-company-required error-company-maxLength"
        aria-disabled={isDisabled}
        aria-invalid={errors.company ? 'true' : 'false'}
        aria-required="true"
        className={style.contactFormField}
        name="company"
        placeholder="Who do you represent?"
        ref={register({ required: true, maxLength: 50 })}
        type="text"
      />
      {errors.company && errors.company.type === 'required' && (
        <div
          className={style.contactFormError}
          id="error-company-required"
          role="alert"
        >
          Please write your who you represent in the above field :)
        </div>
      )}
      {errors.company && errors.company.type === 'maxLength' && (
        <div
          className={style.contactFormError}
          id="error-company-maxLength"
          role="alert"
        >
          This company/organization name is waaaaayyyy too long:)
        </div>
      )}
      <label
        htmlFor="message"
        className={`${style.contactFormLabel} super-paragraph`}
      >
        A little about your project*
      </label>
      <textarea
        aria-describedby="error-message-required"
        aria-disabled={isDisabled}
        aria-invalid={errors.message ? 'true' : 'false'}
        aria-required="true"
        className={style.contactFormField}
        cols="64"
        name="message"
        placeholder="Goals, timeline, budget. Anything you think might be relevant."
        ref={register({ required: true })}
        rows="5"
      />
      {errors.message && errors.message.type === 'required' && (
        <div
          className={style.contactFormError}
          id="error-message-required"
          role="alert"
        >
          Let me know a little about your project in the above field :)
        </div>
      )}
      <fieldset className={style.formSubmitSet}>
        <input
          aria-describedby="error-fullName-required error-fullName-maxLength error-email-required error-email-validate error-company-required error-company-maxLength error-message-required"
          aria-disabled={isDisabled}
          className={`${style.contactFormSubmit} button-two dark-style`}
          value="Send"
          type="submit"
        />
        <ContactSuccess active={isDisabled} />
      </fieldset>
      <p hidden>
        <label>
          Don’t fill this out: <input name="data-field" ref={register()} />
        </label>
      </p>
    </form>
  )
}

export default ContactForm
