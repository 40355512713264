import React from 'react'
import Head from '../components/head'
import Layout from '../components/layout'
import TextHighlight from '../components/textHighlight'
import ContactLink from '../components/contactLink'
import ContactForm from '../components/contactForm'
import { graphql, useStaticQuery } from 'gatsby'
import '../styles/contact.css'

//check to swap with stateless component
const ContactPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            mail
            linkedin
          }
        }
      }
    `
  )
  return (
    <Layout page="Work">
      <Head title="Contact" />
      <div className="contact-content content-gutter">
        <section className="cols col-1">
          <h2>
            Have an interesting <TextHighlight>Project </TextHighlight>?
          </h2>
          <p className="super-paragraph">
            Happy to hear all about it.
            <br />
            Please fill out the following information.
          </p>
          <ContactForm />
        </section>
        <section className="cols col-2 contact-links">
          <ul>
            <ContactLink
              to={`mailto:${data.site.siteMetadata.mail}`}
              header="For "
              highlight="General Inquiries"
            >
              office<TextHighlight>@</TextHighlight>monodon.net
            </ContactLink>
            <ContactLink
              to={data.site.siteMetadata.linkedin}
              header="Connect On "
              highlight="Linkedin"
            >
              linkedin.com/in/<TextHighlight>monodon</TextHighlight>
            </ContactLink>
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default ContactPage
