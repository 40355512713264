import React from 'react'
import style from './contactSuccess.module.css'

//check to swap with stateless component
const ContactSuccess = props => {
  return (
    <div className={style.contactSuccess} data-active={props.active}>
      <h3>Got It!</h3>
      <div className="util-flex">
        <p>We'll get back to you by mail (usually within 24hrs).</p>
        <a className="button-one dark-style" href="/contact">
          Continue
        </a>
      </div>
    </div>
  )
}

export default ContactSuccess
