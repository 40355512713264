import React from 'react'
import TextHighlight from './textHighlight'
import style from './contactLink.module.css'

class ContactLink extends React.Component {
  render() {
    return (
      <li className={style.contactLinkList}>
        <h2 className={style.contactLinkHeader}>
          {this.props.header}
          <TextHighlight>{this.props.highlight}</TextHighlight>
        </h2>
        <a
          className={`${style.contactLink} super-paragraph`}
          href={this.props.to}
          target="_blank"
          rel="noopener noreferrer"
        >
          {this.props.children}
        </a>
      </li>
    )
  }
}

export default ContactLink
